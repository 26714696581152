<template>
  <div>
    <div class="commonInputTopBox">
      <pageHeader :pagename="'设备分类管理'" :total="pageCurrent.total" />

      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="搜索">
          <el-input placeholder="分类名" v-model="data.keyword"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" @click="openCreateNewType"
            >新增设备分类</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="layoutTabaleBorder">
      <el-table :data="pageCurrent.tableData" style="width: 100%">
        <el-table-column prop="type_id" label="ID" width="150">
        </el-table-column>
        <el-table-column prop="equipment_type_name" label="设备分类名称">
        </el-table-column>

        <!-- <el-table-column label="" fixed="right" width="150">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button type="primary" >
                编辑<i class="el-icon-arrow-down"></i>
              </el-button>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :data-id="scope.row.id" @click="openEditType">修改分类名</el-dropdown-item>
                <el-dropdown-item >删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </template>
        </el-table-column> -->
        <el-table-column label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="openEditType(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 点击事件触发 -->
    <!-- <el-dialog title="修改设备分类名称" :visible.sync="dialogFormVisibleEdit">
      <el-form :model="formEdit">
        <el-form-item label="设备分类id" label-width="80px">
          <el-input v-model="formEdit.id" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        
        <el-form-item label="设备分类名称" label-width="80px">
          <el-input v-model="formEdit.classification_name" autocomplete="off"></el-input>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="updateFirstLevel()">确 定</el-button>
      </div>
    </el-dialog> -->

    <!-- 分页器 -->
    <div class="layoutBorder">
      <Pagination
        :total="pageCurrent.total"
        :page.sync="pageCurrent.currentPage"
        @pagination="getList()"
        :limit.sync="pageCurrent.pageSize"
      />
    </div>
  </div>
</template>

<script>
// 载入接口
// import { 接口函数 } from "@/api/接口函数定义文件";
import {
  equipment_library_type_query,
  equipment_library_type_create,
  equipment_library_type_update,
  equipment_library_type_delete
} from '@/api/manage/operation/equipment/equipment_library.js';

// 载入组件
// import 组件名 from "@/components/组件文件.vue";
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'equipmentClassification',
  data() {
    return {
      data: {
        keyword: null
      },
      formEdit: {},
      status: {},
      dialogFormVisibleEdit: false,
      pageCurrent: {
        tableData: [],
        currentPage: 1,
        total: 0,
        pageCount: 0
      }
    };
  },
  components: {
    Pagination
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http(
        equipment_library_type_query({
          querypage: this.pageCurrent.currentPage,
          keyword: this.data.keyword
        }),
        res => {
          console.log(res);
          if (res.code == 200) {
            this.pageCurrent.total = res.data.length;
            this.pageCurrent.currentPage = res.data.currentPage;
            this.pageCurrent.pageCount = res.data.pageCount;
            this.pageCurrent.tableData = res.data;
          }
        }
      );
    },
    openCreateNewType() {
      this.$prompt('新增设备分类名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          this.$http(
            equipment_library_type_create({
              equipment_type_name: value
            }),
            res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.msg
                });
                this.getList();
              } else {
                this.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 5 * 1000
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
    },
    openEditType(row) {
      console.log(row);
      this.$prompt('修改设备分类名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          this.$http(
            equipment_library_type_update({
              equipment_type_name: value,
              type_id: row.type_id
            }),
            res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.msg
                });
                this.getList();
              } else {
                this.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 5 * 1000
                });
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消编辑'
          });
        });
    },
    handleDelete(row) {
      row;
      this.$confirm('是否确定删除', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '删除',
        cancelButtonText: '取消删除'
      })
        .then(() => {
          equipment_library_type_delete({
            type_id: row.type_id
          }).then(res => {
            if (res.code == 200) {
              this.$message.success('成功删除');
              this.$router.go(0);
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          });
        });
    }
  }
};
</script>

<style type="scss"></style>
