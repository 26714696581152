import request from '@/utils/request'



// 查询
export function equipment_library_query(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/query',
        method: 'post',
        data: data
    })
}

// 创建
export function equipment_library_create(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/create',
        method: 'post',
        data: data
    })
}


// 更新
export function equipment_library_update(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/update',
        method: 'post',
        data: data
    })
}


// 删除
export function equipment_library_del(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/del',
        method: 'post',
        data: data
    })
}


// 设备库分类接口 -------------------------------------------------------------

// 查询
export function equipment_library_type_query(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/type/query',
        method: 'post',
        data: data
    })
}

// 创建
export function equipment_library_type_create(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/type/create',
        method: 'post',
        data: data
    })
}
// 更新
export function equipment_library_type_update(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/type/update',
        method: 'post',
        data: data
    })
}
// 更新
export function equipment_library_type_delete(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/type/delete',
        method: 'post',
        data: data
    })
}

// 设备品牌分类接口 -------------------------------------------------------------


// 查询
export function equipment_library_brand_query(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/brand/query',
        method: 'post',
        data: data
    })
}


// 创建
export function equipment_library_brand_create(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/brand/create',
        method: 'post',
        data: data
    })
}